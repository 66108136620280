@import '../../style/abstracts/_abstracts-dir';

.hero {
  display: flex;
  align-items: center;
  width: 100%;

  &__content {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }

  &__heading {
    font-size: 30px;
    color: #000000;
  }

  &__copy {
    line-height: 25px;
    padding: 0 40px;
    font-size: 25px;
    color: #000000;
  }

}

.btn-container {
  display: flex;
  gap: 1rem;
  margin-top: 3rem;
  margin-left: 10%;
}

.btn {
  text-decoration: none;
  padding: 15px 20px;
  border-radius: 30px;
  transition: all 300ms ease-in;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  font-size: 1rem;
  line-height: 1.5;

  &--primary {
    color: var(--color-white);
    background: var(--color-black);

    &:hover {
      background: lighten(#000000, 10%);
    }
  }

  &--ghost {
    color: var(--color-black);
    background: transparent;
    border: 1px solid var(--color-black);

    &:hover {
      color: var(--color-white);
      background: var(--color-black);
    }
  }

}

.container {
  --container-width: 100rem !important;
  margin-bottom: 100px !important;
}

.container:first-of-type:not(.header__toolbar) {
  margin-top: -50px !important;
}

.hero__content {
  padding-top: 30%;
  position: absolute !important;
  bottom: 0 !important;
  width: 100% !important;
  text-align: center !important;
  height: fill-available !important;
}

.carousel__img {
  width: 100% !important;
  max-height: 650px !important;
  min-height: 400px !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 200px) and (max-width: 400px) {
  .hero__content {
    height: 100% !important;
  }
  .container:first-of-type:not(.header__toolbar) {
    margin-top: -65px !important;
  }
  .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .hero__heading {
    font-size: 25px !important;
    margin-bottom: 0 !important;
  }
  .hero__copy {
    margin-top: 10px !important;
    font-size: 15px;
    padding: 0 20px;
  }
  .btn-container {
    margin-top: 10px;
    margin-right: 10%;
    display: grid;
  }
  .btn {
    padding: 15px 20px;
    border-radius: 30px;
    font-size: 15px;
    line-height: 1;
  }
  .carousel__img {
    min-width: 50% !important;
  }
  .hero__content {
    padding-top: 0 !important;
  }
}

@media only screen and (min-width: 400px) and (max-width: 600px) {
  .container:first-of-type:not(.header__toolbar) {
    margin-top: -65px !important;
  }
  .hero__content {
    height: 100% !important;
  }
  .hero__heading {
    font-size: 30px !important;
  }
  .hero__copy {
    font-size: 15px;
    padding: 0 20px;
  }
  .btn-container {
    margin-top: 10px;
    margin-right: 10%;
    display: grid;
  }
  .btn {
    padding: 15px 20px;
    border-radius: 30px;
    font-size: 15px;
    line-height: 1;
  }
  .carousel__img {
    min-width: 50% !important;
  }
  .hero__content {
    padding-top: 0 !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .container:first-of-type:not(.header__toolbar) {
    margin-top: -65px !important;
  }
  .hero__heading {
    font-size: 30px !important;
  }
  .hero__copy {
    font-size: 20px;
    padding: 0 20px;
  }
  .hero__content {
    padding-top: 10% !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .container:first-of-type:not(.header__toolbar) {
    margin-top: -65px !important;
  }
  .hero__heading {
    font-size: 30px !important;
  }
  .hero__copy {
    font-size: 20px;
    padding: 0 20px;
  }
  .hero__content {
    padding-top: 15% !important;
  }
}

.carousel__div {
  width: 100% !important;
}
